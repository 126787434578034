import React from "react"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/noLimitImg.jpg"

function Ai() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap bemsWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap bemsOverview">
          <div className="textWrap">
            <h2>AI service</h2>
            <p className="bodyText">
              <b>Failure prediction using IoT gateway collected data</b>
              <br />
              - Failure parts and materials required prediction model
              <br />
              - Model for suggesting solutions to similar problems
              <br />
              <br />
              <b>Function</b>
              <br />
              -(Failure Prediction) Real-time monitoring of elevator condition
              and operation status of multiple models (large companies/foreign
              companies and small and medium-sized enterprises), data-based
              accumulation, and operation management with a failure prediction
              system
              <br />
              -(Analytic intelligence) Elevator maintenance/tracking management
              by predicting parts failure through parts life cycle management.
              <br />
              -(Unmanned Logistics Delivery) Elevator link system that allows
              robots to get on and off during unmanned delivery services
              <br />
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Ai
